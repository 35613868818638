
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
  Accordion, AccordionSummary, AccordionDetails, Typography, Button, Box
} from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import CircularProgress from '@mui/material/CircularProgress';
import Link from '@mui/material/Link';

import React, { useState, useEffect } from 'react';

import config from '../../../config';

import { ReactComponent as SpotifyIcon } from "./../../../assets/spotify.svg";
import { ReactComponent as AppleMusicIcon } from "./../../../assets/appleMusic.svg";
import Icon from "@material-ui/core/Icon";
import Grid from '@mui/material/Grid';
import { format, parseISO } from 'date-fns';
import {getIconForTransfer} from '../../../utils'
import {Alert} from '@mui/material'



export default function TransferHistory() {

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [loading, setLoading] = useState(true); // State to track loading state
  const [transfers, setTransfers] = useState([])

  const params = new URLSearchParams(window.location.search);
  const [redirectMessage, setRedirectMessage] = useState(params.get('message'))



  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true); // Set loading state to true
        const response = await fetch(config.apiUrl + "/transfer/history", {
          headers: {
            'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('user')).accessToken,
          }
        });

        const status = await response.status;
        if (status === 200) {
          let data = await response.json()
          setTransfers(data["transfers"])
        } else {
          throw new Error('Failed to get playlists');
        }

      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false); // Set loading state to false after fetch completes
      }
    };

    fetchData();
  }, []);


  const handleRefresh = () => {
    window.location.href = config.transferHistoryPath;
  };



  const getMissingListViewitem = (missingLog, transferId) => {
    let listItems = missingLog.map(log => {
      if (log.length > 0) {
        return <li key={log}>{log}</li>
      }
    });
    return <ol>{listItems}</ol>;
  }

  function getCollectionName(transfer) {
    return (
      <>
        <Icon fontSize="small">
          {getIconForTransfer(transfer.sourceAccountType, transfer.isSourceYTM)}
        </Icon>
        <span> to </span>
        <Icon fontSize="small">
          {getIconForTransfer(transfer.destinationAccountType, transfer.isDestYTM)}
        </Icon>

        <Typography>
        <strong>
          {transfer.sourcePlayListType === "liked"
            ? "Liked/Saved Songs Transfer"
            : transfer.sourcePlayListType === "album"
            ? "Albums transfer"
            : " " + transfer.playlistName}
        </strong>
      </Typography>
        </>
    )
  }


  function getStartDate(transfer) {
    return (
      <Typography variant="span">
        {format(parseISO(transfer.createdAt), "eeee, MMMM do, yyyy 'at' h:mm a")}
      </Typography>
    )
  }
  
  function getCollectionType(transfer) {
    if (transfer.sourcePlayListType === "liked") {
      return (
        <Typography variant="span">
          {"Liked songs"}
        </Typography>
      )
    }
    if (transfer.sourcePlayListType === "album") {
      return (
        <Typography variant="span">
          {"Album"}
        </Typography>
      )
    }
    if (transfer.sourcePlayListType === "playlist") {
      return (
        <Typography variant="span">
          {"Playlist"}
        </Typography>
      )
    }
  }

  function getTransferStatus(transfer) {
    if (transfer.jobStatus === "CREATED") {
      return (
        <Typography variant="span" color="#000000">
          {"Created, we will email you once completed"}
        </Typography>
      )
    }
    if (transfer.jobStatus === "RUNNING") {
      return (
        <Typography variant="span" color="#0d6efd">
          {"Running, we will email you once completed"}
        </Typography>
      )
    }
    if (transfer.jobStatus === "ERROR") {
      return (
        <Typography variant="span" color="#FF0000">
          {"Error"}
        </Typography>
      )
    }
    if (transfer.jobStatus === "COMPLETED_WITH_MISSING") {
      return (
        <Typography variant="span" color="#FFA500">
          {"Completed with missing"}
        </Typography>
      )
    }
    if (transfer.jobStatus === "COMPLETED_REPLACED") {
      return (
        <Typography variant="span" color="#008000">
          {"Completed and replaced missed ones"}
        </Typography>
      )
    }
    if (transfer.jobStatus === "COMPLETED") {
      return (
        <Typography variant="span" color="#008000">
          {"Completed"}
        </Typography>
      )
    }
    if (transfer.jobStatus === "SAVED") {
      return (
        <Typography variant="span" color="#008000">
          {"Saved"}
        </Typography>
      )
    }
    if (transfer.jobStatus === "EXPIRED") {
      return (
        <Typography variant="span" color="#008000">
          {"Expired"}
        </Typography>
      )
    }
  }


  function getCompletionStatus(transfer) {
    return (
      <Typography variant="span">
        {transfer.transferredCount}/{transfer.totalCount}
      </Typography>
    )
  }

  function HistoryTable() {
    return (

      <>

      <Grid item xs={12}>
          <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>

          <Typography component="h2" variant="h6" color="primary" gutterBottom>
                          Your Transfers
                </Typography>
          <Box display="flex" justifyContent="flex-end" p={2}>
            <Button variant="contained" color="primary" startIcon={<RefreshIcon />} onClick={handleRefresh}>
              Refresh
            </Button>
          </Box>

  {redirectMessage === "TRANSFER_STARTED" ? <Alert sx={{ mt: 2, mb: 2 }} severity="success" icon={false} >Your transfer is queued for processing.</Alert> : <></>}

  {redirectMessage === "MISSING_REPLACED" ? <Alert sx={{ mt: 2, mb: 2 }} severity="success" icon={false} >All missing songs have been added to your playlist/album.</Alert> : <></>}

  {isMobile ? (
    <div>
      {transfers.map((item, index) => (
        <Accordion key={index}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>{getCollectionName(item)}{getTransferStatus(item)}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography><strong>Collection Name:</strong> {getCollectionName(item)}</Typography>
            <Typography><strong>Start Time:</strong> {getStartDate(item)}</Typography>
            <Typography><strong>Collection type:</strong> {getCollectionType(item)}</Typography>
            <Typography><strong>Transfer Status:</strong> {getTransferStatus(item)}{item.jobStatus==="SAVED"? <><Link href={config.transferHistoryPath + "/" + item.id + "/complete"} underline="hover">Continue</Link></> :""}</Typography>
            <Typography><strong>Songs Transfered:</strong> {getCompletionStatus(item)}</Typography>
            <Typography><strong>Missed Tracks:</strong>                
                <Box display="flex" justifyContent="center">
                  {item.jobStatus==="COMPLETED_WITH_MISSING"? <><Button variant="contained" color="primary" component={Link} href={`${config.transferHistoryPath}/${item.id}/edit`} sx={{ textDecoration: 'none' }} > &nbsp; Find and add Missed</Button></> :""}
                </Box>
                  {item.missedLog === null ? item.missedLog : getMissingListViewitem(item.missedLog.split("\n"))}
                </Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  ) : (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Collection Name</TableCell>
            <TableCell>Start Time</TableCell>
            <TableCell>Collection type</TableCell>
            <TableCell>Transfer Status</TableCell>
            <TableCell>Songs Transfered</TableCell>
            <TableCell>Missed Tracks</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {transfers.map((item, index) => (
            <TableRow key={index}>
              <TableCell>{getCollectionName(item)}</TableCell>
              <TableCell>{getStartDate(item)}</TableCell>
              <TableCell>{getCollectionType(item)}</TableCell>
              <TableCell>{getTransferStatus(item)}{item.jobStatus==="SAVED"? <><Link href={config.transferHistoryPath + "/" + item.id + "/complete"} underline="hover">Continue</Link></> :""} {item.jobStatus==="COMPLETED_WITH_MISSING"? <><Link href={config.transferHistoryPath + "/" + item.id + "/edit"} underline="hover"> &nbsp; Add Missed</Link></> :""}</TableCell>
              <TableCell>{getCompletionStatus(item)}</TableCell>
              <TableCell>
                <Box display="flex" justifyContent="center">
                {item.jobStatus==="COMPLETED_WITH_MISSING"? <><Button variant="contained" color="primary" component={Link} href={`${config.transferHistoryPath}/${item.id}/edit`} sx={{ textDecoration: 'none' }} > &nbsp; Find and add Missed</Button></> :""}
                </Box>
                {item.missedLog === null ? item.missedLog : getMissingListViewitem(item.missedLog.split("\n"), item.id)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )}

          </Paper>
      </Grid>
      </>
    )
  }

  return (
    
    <>
    {loading ? <CircularProgress />
        : <><HistoryTable></HistoryTable></>
    }
</>
  );

};

